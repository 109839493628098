<template>
  <CohortListGrid />
</template>

<script>

import CohortListGrid from '@/modules/cohort/components/CohortListGrid.vue'

export default {
  components: {
    CohortListGrid,
  },
}
</script>
