<template>
  <b-card-actions
    ref="cohortGrid"
    title="Cohorts"
    :action-refresh="true"
    @refresh="refresh(true)"
  >
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      :to="{ name: 'cohort-add'}"
    >
      + Add new
    </b-button>

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">

      <div style="margin-top: 10px; margin-right: 10px">
        <b-form-checkbox
          v-model="showSoftDeleted"
        >
          Show soft deleted
        </b-form-checkbox>

      </div>
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1" />
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>

    </div>

    <!-- table -->
    <vue-good-table
      :columns="tableColumns"
      :rows="filteredCohort"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'date_created'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.date_created | dateFormat }}</span>
        </span>

        <span v-else-if="props.column.field === 'name'">
          {{ props.row.name }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ statusLable(props.row.status) }}
          </b-badge>
          <span v-if="props.row.exec_status">
            /
            <b-badge :variant="execStatusVariant(props.row.exec_status)">
              {{ props.row.exec_status }}
            </b-badge>
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="routeToEditPage(props.row.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="routeToAnalysisStatsPage(props.row.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Analysis Stats</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="props.row.status == 'A'"
                @click="refreshCohort(props.row.id)"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  class="mr-50"
                />
                <span>Refresh</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="props.row.status == 'A'"
                @click="onDeactivateCohort(props.row.id)"
              >
                <feather-icon
                  icon="PauseCircleIcon"
                  class="mr-50"
                />
                <span>Deactivate</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="props.row.status != 'A'"
                @click="onActivateCohort(props.row.id)"
              >
                <feather-icon
                  icon="PlayCircleIcon"
                  class="mr-50"
                />
                <span>Activate</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="props.row.status != 'D'"
                @click="onSoftDeleteCohort(props.row.id)"
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span>Soft Delete</span>
              </b-dropdown-item>

            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-actions>

</template>

<script>
import { defineComponent } from '@vue/composition-api'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { createNamespacedHelpers } from 'vuex'
import {
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormCheckbox,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

const { mapActions, mapGetters } = createNamespacedHelpers('cohort')

export default defineComponent({
  name: 'JourneyGrid',
  components: {
    VueGoodTable,
    // BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCardActions,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  filters: {
    dateFormat(dateStr) {
      if (!dateStr) return ''
      return moment.utc(dateStr).local().format('YYYY-MM-DD HH:mm:ss')
    },
  },
  setup() {},
  data() {
    return {
      searchTerm: '',
      pageLength: 10,
      showSoftDeleted: false,
      tableColumns: [
        { label: 'Name', field: 'name' },
        { label: 'Created By', field: 'created_by' },
        { label: 'Creted', field: 'date_created' },
        { label: 'Status/Exec', field: 'status' },
        { label: '', field: 'action' },
      ],
    }
  },
  computed: {
    filteredCohort() {
      return this.allCohort.filter(item => !(item.status === 'D' && !this.showSoftDeleted))
    },
    statusLable() {
      const items = {
        /* eslint-disable key-spacing */
        A : 'Active',
        D : 'Soft Deleted',
        I : 'Inactive',
        /* eslint-enable key-spacing */
      }
      return status => items[status]
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        A : 'light-success',
        D : 'light-danger',
        I : 'light-warning',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    execStatusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        SUCCEEDS : 'light-success',
        FAILED : 'light-danger',
        STARTED : 'light-warning',
        PENDING : 'light-warning',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    ...mapGetters(['allCohort']),
  },
  mounted() {
    this.refresh(false)
  },
  methods: {
    routeToEditPage(id) {
      this.$router.push({ name: 'cohort-edit', params: { cohort_id: id } })
    },
    routeCreateAnalysisPage(id) {
      this.$router.push({ name: 'create-cohort-analysis', params: { cohort_id: id } })
    },
    routeToListAnalysisPage(id) {
      this.$router.push({ name: 'list-cohort-analysis', params: { cohort_id: id } })
    },
    routeToAnalysisStatsPage(id) {
      this.$router.push({ name: 'cohort-analysis-stats', params: { cohort_id: id } })
    },
    refresh(force) {
      const busyTarget = this.$refs.cohortGrid
      busyTarget.showLoading = true
      this.getAllCohort(force).finally(() => {
        busyTarget.showLoading = false
      })
    },
    refreshCohort(id) {
      this.addToRefreshQueue(id)
    },
    onActivateCohort(id) {
      this.activateCohort(id)
    },
    onDeactivateCohort(id) {
      this.deactivateCohort(id)
    },
    onSoftDeleteCohort(id) {
      this.softDeleteCohort(id)
    },
    ...mapActions(['getAllCohort', 'addToRefreshQueue', 'activateCohort',
      'deactivateCohort', 'softDeleteCohort']),
  },
})
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.vgt-responsive {
    overflow-x: visible;
    //overflow-y: visible;
}
</style>
